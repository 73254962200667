.wrap {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование по вертикали */
    margin-bottom: 1rem;
    padding: 15px;
}
.h1 {
    margin: 0;
}
.h3{
    margin: 15px;
}
.lines {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Центрирование по горизонтали */
}
.line {
    border-top: 2px solid #5c5a5b;
    margin: 0 100px; /* Равное расстояние между линиями */
    width: 70px;
    height: 30px;
}
.spisok{
    list-style: none; /* Убираем маркеры у списка */
    padding: 0px; /* Убираем внутренний отступ у списка */

}
.spisok li::before {
    content: "✔️"; /* Здесь используется символ галочки */
    color: #8E7AB2; /* Розовый цвет */
    margin-right: 10px; /* Расстояние между галочкой и текстом */
}
li{
    padding: 10px;
}