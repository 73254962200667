.myLink{
    -webkit-tap-highlight-color: transparent; /* Отключаем обводку на мобильных устройствах */
    outline: none; /* Убираем обводку */
}
a
{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1rem;
}

a img{
    position: absolute;
    left: 0px;
}
@media (min-width: 768px) {

    a img {
        position: static;
        left: auto;

    }
}

/* @media only screen and (max-width: 1024px) {
    a {
        font-size: 1.5rem;
    }
} */