.content {
    /*grid-area: c;*/
    /*background: rgba(225, 225, 225, 0.7);*/
    /*width: 100%;*/
    /*height: 200vh;*/
    overflow: hidden;
    text-align: center;  /* Центрирует текст */
}
.wrapSet{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    grid-gap: 30px;
}