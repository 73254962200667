/* Общие стили для таблицы */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    position: relative;

    z-index: 1; /* Для управления слоем элемента */
}

/* Стили для заголовков таблицы */
th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    text-align: center; /* Центрирование содержимого по горизонтали */
    vertical-align: middle; /* Центрирование содержимого по вертикали */
    whiteSpace: 'pre-line'
}
@media only screen and (max-width: 1024px) {
    /* Применяем стили к <th> с классом "specific-th" */
    .specific__td {
        width: 40px;
    }
}
th {
    background-color: #f2f2f2;
}

/* Подсветка при наведении на строку таблицы */
tr:hover {
    background-color: #f5f5f5;
}
/* Подсветка области при наведении на ячейку таблицы */
td:hover {
    background-color: #e0e0e0;
    outline: 2px solid #ec70e8; /* Розовая рамочка */
}

.caption {
    font-family: 'Playfair Display', sans-serif;
    font-size: 2.5rem;
}

.table__Background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Помещаем фон под таблицу */
    /*background-image: url('../../image/slide/1.JPG'); !* Замените на путь к вашему изображению *!*/
    background-size: cover;
    background-position: center;
    filter: blur(15px); /* Размытие фона для эффекта параллакса */
}