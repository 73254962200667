.footer {
    background: #8E7AB2;
    text-align: center;
    color: white;
    margin-top: 20px;
    padding: 25px;
}

.footer div {
    display: flex;
    align-items: center;
    justify-content: center;
}
