.container {
    width: 250px;
    height: auto;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 150px 150px 25px 25px;
    margin: auto;
    text-align: center;
    position: relative;

}
.container:hover{
    cursor: pointer;
    background: linear-gradient(to right, rgba(170, 170, 255, 0.7) 0%, rgba(251, 186, 248, 0.7) 100%);
}
.h1{
    margin-bottom: 10px;
    font-size: 1.8rem;
}
h3{
    font-size: 1.1rem;
}
.container:hover h1{
    color: white;
}
.circle{
    width: 150px;
    height: 150px;
    background-color: #6c757d;
    border-radius: 50%;
    border: white;
    color: white;
    line-height: 100px;
    margin: 20px auto;
    overflow: hidden;
}
.circle img {
    max-width: 150px;
}
.button{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;

}

.wrapText{
    margin-bottom: 20px;
}
.contH1{
    height: 200px;
}
@media only screen and (min-width: 1024px) {
    .container {
        width: 280px;
    }
}