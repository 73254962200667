.doposle{
    padding: 10px;
}

.wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1000px; /* Задайте максимальную ширину по вашему выбору */
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
}
.image {
    max-width: 100%;
    height: auto;
}
.blockImage{
    margin-right: 20px;
}
/*@media screen and (max-width: 768px) {*/
/*    .image {*/
/*        max-width: calc(50% - 5px); !* Для двух изображений в ряду на экранах шириной до 768px *!*/
/*        margin-right: 5px;*/
/*        margin-bottom: 10px; !* Добавляем отступ снизу для создания вертикального интервала между изображениями *!*/
/*    }*/
/*}*/