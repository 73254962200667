.wrap{
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.map {
    display: grid;
    place-items: center;
    width: 100%;
    aspect-ratio: 4 / 3; /* Пропорции карты, например, 4:3 */
}
.contacts{
    display: grid;
    place-items: center;
    gap:10px;
}
.contacts img{
    max-width: 300px;
}
.address{
    padding: 10px;
}
.socialIcons {
    display: flex;
    justify-content: center; /* Выровнять по центру */
    gap: 25px; /* Отступы между иконками */
}
