/*=============== GOOGLE FONTS ===============*/
@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

/*=============== VARIABLES CSS ===============*/
:root {
    /*========== Colors ==========*/
    /*Color mode HSL(hue, saturation, lightness)*/
    --white-color: hsl(0, 0%, 100%);
    --black-color:#ec70e8;
    /*#ec70e8*/
    /*#c779b3*/
    /*========== Font and typography ==========*/
    /*.5rem = 8px | 1rem = 16px ...*/
    --body-font:  'Oswald', sans-serif;
    --h1-font-size: 2rem;
    --normal-font-size: 1rem;
    --small-font-size: .813rem;

}

/*=============== BASE ===============*/
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.button {
    color: white;
    padding: 10px 24px;
    margin: 20px auto;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    text-decoration: none;
    font-family: 'Oswald', sans-serif;
    font-size: 1.1rem;
    background: linear-gradient(to right, rgba(170, 170, 255, 1) 0%, rgba(251, 186, 248, 1) 100%);;
}

body,
input,
button {
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
}

a {
    text-decoration: none;
}


/*=============== LOGIN ===============*/
.login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(25px); /* Используем backdrop-filter для создания эффекта матового стекла */
}
.login::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; /* Помещаем затемнение под форму */
    background: rgba(255, 255, 255, 0.7); /* Изменяем цвет, прозрачность и цвет подложки */
    z-index: -1; /* Помещаем подложку под форму */
    /*backdrop-filter: blur(25px); !* Используем backdrop-filter для создания эффекта матового стекла *!*/


}


.login__form {
    position: relative;
    margin-inline: auto; /* Центрируем блок по горизонтали */
    max-width: 400px; /* Максимальная ширина формы */
    padding: 2.5rem 1rem;
    color: var(--white-color);
    z-index: 1; /* Поднимаем форму над подложкой */
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрируем содержимое по вертикали */
}

.login__title {
    text-align: center;
    font-size: var(--h1-font-size);
    font-family: --body-font;
    margin-bottom: 1.25rem;
    color: var(--black-color);
}

.login__inputs,
.login__box {
    display: grid;
}

.login__inputs {
    row-gap: 1.25rem;
    margin-bottom: 1rem;
}

.login__box {
    grid-template-columns: 1fr max-content;
    column-gap: .75rem;
    align-items: center;
    border: 2px solid #c779b3;
    padding-inline: 1.25rem;
    border-radius: 4rem;
    color: var(--black-color);
}

.login__input,
.login__button {
    border: none;
    outline: none;
}

.login__input {
    width: 100%;
    background: none;
    color: var(--black-color);
    padding-block: 1rem;
}

.login__input::placeholder {
    color: var(--black-color);
}

.login__box i {
    font-size: 1.25rem;
}


.login__check {
    margin-bottom: 1rem;
    font-size: var(--small-font-size);
}

.login__check-box {
    column-gap: .5rem;
    color: var(--black-color);
}

.login__check-input {
    width: 1rem;
    height: 1rem;
    accent-color: var(--white-color);
}

.login__forgot {
    color: var(--black-color);
}

.login__forgot:hover {
    text-decoration: underline;
}

.login__button {
    width: 50%;
    max-width: 200px;
    padding: 1rem;
    background-color: var(--white-color);
    border-radius: 4rem;
    color: var(--black-color);
    font-weight: 500;
    cursor: pointer;
    display: block;
}

.login__register {
    font-size: var(--small-font-size);
    text-align: center;
}

.login__register a {
    color: var(--white-color);
    font-weight: 500;
}

.login__register a:hover {
    text-decoration: underline;
}

/*=============== BREAKPOINTS ===============*/
/* For medium devices */
@media screen and (min-width: 576px) {
    .login {
        justify-content: center;
    }
    .login__form {
        width: 420px;
        padding-inline: 2.5rem;
    }
    .login__title {
        margin-bottom: 2rem;
    }
}

.close__button{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonContainer{
    display: flex;
    justify-content: center;
    margin-top: 100px; /* Добавьте отступ сверху по вашему усмотрению */
}
