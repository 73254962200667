.wrap {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование по вертикали */
    margin-bottom: 1rem;
}
.h1 {
    margin: 0;
}
.h3{
    margin: 15px;
}
.lines {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center; /* Центрирование по горизонтали */
}
.line {
    border-top: 2px solid #5c5a5b;
    margin: 0 100px; /* Равное расстояние между линиями */
    width: 70px;
    height: 30px;
}
ol > li {
    padding-left: 20px;
    position: relative;
    margin: 10px;
}
ol > li::before {
    content: '✔';
    position: absolute;
    top: 0;
    left: 0;
    color: green; /* Замените на желаемый цвет */
}