.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
}

.offerSection {
    text-align: center;
    margin-bottom: 20px;
}

.offerTitle {
    font-size: 2rem;
    color: #C779B2;
    margin-bottom: 10px;
}

.offerDescription {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 20px;
}

.offerImage {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
}

.formSection {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.formTitle {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 15px;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.inputGroup {
    display: flex;
    flex-direction: column;
}

.label {
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.input {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submitButton {
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #8E7AB2;;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}


.submitButton:hover {
    background-color: #C779B2;
}

.submitButton:disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.sentButton {
    background-color: #28a745; /* Зеленый цвет */
    color: #fff;
}
