.button {
    text-decoration: none;
    position: relative;
    border: none;
    font-size: 14px;
    font-family: inherit;
    color: #fff;
    width: 15em;
    height: 3em;
    line-height: 2em;
    text-align: center;
    background: linear-gradient(90deg, rgba(170, 170, 255, 1), rgba(251, 186, 248, 1), #ffffff, rgba(170, 170, 255, 1));
    background-size: 300%;
    border-radius: 20px;
    z-index: 0;
    overflow: hidden; /* Скрытие размытой области, которая выходит за пределы кнопки */
    /*width: 190px;*/
}

.button::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(90deg, rgba(170, 170, 255, 1), rgba(251, 186, 248, 1), #ffffff, rgba(170, 170, 255, 1));
    background-size: 400%;
    border-radius: 10px; /* Здесь используется такой же радиус, как у кнопки */
    filter: blur(15px); /* Начальное размытие */
    animation: ani 8s linear infinite; /* Анимация размытия вокруг кнопки */
}

/*@keyframes gradient {*/
/*    0% {*/
/*        background-position: 0% 50%;*/
/*    }*/
/*    50% {*/
/*        background-position: 100% 50%;*/
/*    }*/
/*    100% {*/
/*        background-position: 0% 50%;*/
/*    }*/
/*}*/


@keyframes ani {
    0% {
        background-position: 0%;
    }

    100% {
        background-position: 400%;
    }
}

.button:hover::before {
    filter: blur(40px); /* Продолжение размытия при наведении */
}
/*.button:active {*/
/*    background: linear-gradient(32deg,#03a9f4,#f441a5);*/

/*}*/

@media only screen and (min-width: 1024px) {
    .button{
        width: 17em;
        height: 3em;
    }
}