
.swiper {
    width: 100%;
    height: 100%;

}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.wrap{
    margin-bottom: 20px;
}