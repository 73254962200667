.header {
    grid-area: h;
    background: #8E7AB2;
    grid-template-columns: 1fr; /* Одна колонка для узких экранов */
    grid-gap: 10px;
    display: grid;
    padding: 5px;
}

.header div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
}

.header .CustomLink a {
    color: white;
}
.socialIcons {
    gap:10px;
}

@media (min-width: 768px) {
    .header {
        grid-template-columns: 1fr 2fr 1fr; /* Три колонки для широких экранов */
        align-items: center; /* Выравнивание по центру по вертикали */
    }
    .header div:nth-child(1) {
        justify-content: flex-start; /* Номер телефона слева */
    }
    .socialIcons {
        justify-content: center; /* Социальные иконки по центру */
    }
    .schedule {
        justify-content: flex-end; /* Расписание справа */
    }
}
