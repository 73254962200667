
.wrap {
    grid-area: n;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center; /* Выравнивание элементов по центру по вертикали */
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}
.stickyNavbar {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 100;
    /* Дополнительные стили для прилипающей панели, если нужно */
}
.wrap div:first-child img {
    display: grid;
    justify-self: start; /* Первый div выравнивается по левому краю */
    max-width: 80px; /* Замените этот размер на желаемое ограничение */
    margin-left: 10px;

}
.wrap > div:nth-child(2){
    display: grid;
    place-items: center;
}

.blockLink {
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    place-items: center;
    gap: 30px;

}

.wrap .navBtn{
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    visibility: hidden;
    display: none;
    opacity: 0;
    font-size: 1.8rem;
    -webkit-tap-highlight-color: transparent; /* Отключаем обводку на мобильных устройствах */
    outline: none; /* Убираем обводку */

}
.nav{
    display: flex;
    align-items: center; /* Выравнивание по вертикали */
}
@media only screen and (max-width: 1024px) {
    .wrap .navBtn{
        visibility: visible;
        opacity: 1;
        display: block;
    }
    .nav{
        position: fixed;
        top:0;
        right: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        transition: 1s;
        transform: translateY(-100vh);
        flex-direction: column;
        background-color: white;
        z-index: 2;
        transition-delay: 0.3s; /* Добавляем задержку в 0.5 секунды перед началом анимации */
    }
    .wrap .responsive_nav{
        transform:none;
    }
    .navCloseBtn{
        position: absolute;
        top:2rem;
        right: 2rem;
    }
    .blockLink{
        grid-template-columns:1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
    }
}
.navBtn:active,
.navCloseBtn:active {
    color: #ec70e8;
}
.navBtn{
    color:#5c5a5b;
}
