/* Подключаем шрифты через @import */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora&display=swap');

.app-wrapper {
    display: grid;
    grid-template-rows: 120px 90px 1fr;
    grid-template-areas:
    'h'
    'n'
    'c';
    width: 100%;
    min-height: 100vh;
}

@media (min-width: 768px) {
    .app-wrapper {
        grid-template-rows: 80px 100px 1fr;
    }
}

@media (max-width: 768px) {
    .app-wrapper {
        grid-template-columns: 1fr;
    }
}

/* Стиль для заголовков и текста */
h1 {
    font-family: 'Lora', serif; /* Используй Lora, как она подключена через Google Fonts */
    font-size: 2rem;
    color: #5c5a5b;
}

h2 {
    font-size: 1.5rem;
}

div {
    font-family: 'Oswald', sans-serif; /* Используем Oswald, как подключено через Google Fonts */
    font-size: 1.1rem;
    color: #5c5a5b;
}

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
