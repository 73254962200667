li{
    padding: 10px;
}
.spisok{
    list-style: none; /* Убираем маркеры у списка */
    padding: 0px; /* Убираем внутренний отступ у списка */

}
.spisok li::before {
    content: "❌"; /* Символ красного крестика */
    color: #ff0000; /* Красный цвет */
    margin-right: 10px; /* Расстояние между крестиком и текстом */
}

.wrap{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (min-width: 768px) {
    .column3 {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 16px; /* Регулируйте размер зазора между элементами, по вашему желанию */
    }
}